import { HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { CategoryDescription, CategoryChildren } from '@graphcommerce/magento-category'
import {
  FilterTypes,
  ProductFiltersPro,
  ProductFiltersProAllFiltersChip,
  ProductFiltersProAllFiltersSidebar,
  ProductFiltersProClearAll,
  ProductFiltersProFilterChips,
  ProductFiltersProLimitChip,
  ProductFiltersProSortChip,
  ProductFiltersQuery,
  ProductListCount,
  ProductListFilters,
  ProductListFiltersContainer,
  ProductListPagination,
  ProductListParams,
  ProductListParamsProvider,
  ProductListQuery,
  ProductListSort,
} from '@graphcommerce/magento-product'
import { LayoutTitle, StickyBelowHeader } from '@graphcommerce/next-ui'
import { Box, Container } from '@mui/material'
import { CategoryPageQuery } from '../../graphql/CategoryPage.gql'
import { ProductFiltersProLayoutSidebar } from './ProductFiltersProLayoutSidebar'
import { ProductListItems } from './ProductListItems'

export type ProductListFilterLayoutProps = ProductListQuery &
  ProductFiltersQuery &
  CategoryPageQuery &
  HygraphPagesQuery & {
    filterTypes?: FilterTypes
    params?: ProductListParams
    id: string
    title: string
  }

export function CategoryFilterLayout(props: ProductListFilterLayoutProps) {
  const { params, filters, products, filterTypes, title, id, categories, pages } = props
  const category = categories?.items?.[0]
  const page = pages?.[0]

  const isCategory =
    params &&
    category &&
    products?.items &&
    filterTypes &&
    (category?.display_mode === 'PRODUCTS_AND_PAGE' || category?.display_mode === 'PRODUCTS')

  if (!(params && products?.items && filterTypes)) return null

  const { total_count, sort_fields, page_info } = products

  const items = (
    <ProductListItems
      items={products.items}
      loadingEager={6}
      title={title}
      sx={{
        '.ProductListItem-root': {
          '& .ProductListItem-subtitle, ': { display: 'block' },
          '& .ProductListItem-titleContainer': {
            rowGap: 2,
          },
        },
      }}
    />
  )
  const content = (
    <>
      <LayoutTitle
        variant='h1'
        gutterTop={false}
        sx={(theme) => ({
          mb: theme.spacings.xxs,
          mt: { xs: theme.spacings.sm, md: theme.spacings.lg },
          '& h1': { textAlign: 'left', ml: 0, mr: 'auto' },
        })}
        gutterBottom={!isCategory || (!category?.description && category?.children?.length === 0)}
      >
        {category?.name ?? page?.title}
      </LayoutTitle>
      <CategoryDescription
        sx={(theme) => ({
          pl: 0,
          ml: 0,
          mr: 'auto',
          textAlign: 'left',
          typography: theme.typography.body1,
          '& a': {
            color: 'primary.main',
            transition: 'color 0.25s',

            '&:hover': {
              color: 'primary.dark',
            },
          },
        })}
        description={category?.description}
      />
      <CategoryChildren
        sx={(theme) => ({
          gap: theme.spacings.xxs,
          mb: { md: 0 },
          display: 'grid',
          justifyContent: 'start',
          '& a:first-child': { ml: 0 },
        })}
        params={params}
      >
        {category?.children}
      </CategoryChildren>
    </>
  )

  if (import.meta.graphCommerce.productFiltersPro) {
    const horizontalFilters = (
      <ProductListFiltersContainer>
        <ProductFiltersProFilterChips />
        <ProductFiltersProSortChip total_count={total_count} sort_fields={sort_fields} />
        <ProductFiltersProLimitChip />
        <ProductFiltersProAllFiltersChip total_count={total_count} sort_fields={sort_fields} />
      </ProductListFiltersContainer>
    )

    return (
      <ProductFiltersPro
        key={id}
        params={params}
        aggregations={filters?.aggregations}
        appliedAggregations={products?.aggregations}
        filterTypes={filterTypes}
      >
        {import.meta.graphCommerce.productFiltersLayout === 'SIDEBAR' ? (
          <Container maxWidth='lg' sx={{ height: { md: '100%' } }}>
            <Box sx={{ display: { md: 'none' } }}>{content}</Box>
            <StickyBelowHeader sx={{ display: { md: 'none' } }}>
              {horizontalFilters}
            </StickyBelowHeader>

            <Box sx={(theme) => ({ display: { md: 'flex' }, gap: theme.spacings.xxl })}>
              <ProductFiltersProLayoutSidebar
                clearAll={<ProductFiltersProClearAll />}
                sidebarFilters={
                  <ProductFiltersProAllFiltersSidebar
                    total_count={total_count}
                    sort_fields={sort_fields}
                  />
                }
              />
              <Box sx={{ flexGrow: { md: 1 } }}>
                <Box sx={{ display: { xs: 'none', md: 'unset' } }}>{content}</Box>
                <ProductListCount sx={{ width: '100%' }} total_count={total_count} />
                <Box className='items'>{items}</Box>
                <ProductListPagination page_info={page_info} params={params} />
              </Box>
            </Box>
          </Container>
        ) : (
          <>
            <StickyBelowHeader>{horizontalFilters}</StickyBelowHeader>
            <Container maxWidth={false}>
              <ProductListCount total_count={total_count} />
              {items}
              <ProductListPagination page_info={page_info} params={params} />
            </Container>
          </>
        )}
      </ProductFiltersPro>
    )
  }

  if (!import.meta.graphCommerce.productFiltersPro) {
    return (
      <>
        <StickyBelowHeader>
          <ProductListParamsProvider value={params}>
            <ProductListFiltersContainer>
              <ProductListSort sort_fields={sort_fields} total_count={total_count} />
              <ProductListFilters {...filters} filterTypes={filterTypes} />
            </ProductListFiltersContainer>
          </ProductListParamsProvider>
        </StickyBelowHeader>
        <Container maxWidth={false}>
          <ProductListCount total_count={total_count} />
          {items}
          <ProductListPagination page_info={page_info} params={params} />
        </Container>
      </>
    )
  }

  return null
}
