import { Image } from '@graphcommerce/image'
import { CategoryDescription } from '@graphcommerce/magento-category'
import { LayoutTitle } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { ButtonBase, Typography, Button, Container, Box } from '@mui/material'

export function CategorySubHome(props) {
  const { category } = props

  if (!category) return null

  return (
    <>
      <LayoutTitle
        component='h1'
        sx={(theme) => ({
          mt: theme.spacings.lg,
          marginBottom: 0,
        })}
      >
        {category?.name}
      </LayoutTitle>
      <CategoryDescription
        description={category.description}
        sx={(theme) => ({
          margin: `${theme.spacings.xs} auto ${theme.spacings.sm}`,
          [theme.breakpoints.up('xl')]: {
            width: '50%',
            maxWidth: 1024,
          },

          '& a': {
            color: 'primary.main',
            transition: 'color 0.25s',

            '&:hover': {
              color: 'primary.dark',
            },
          },
        })}
      />
      <Container
        maxWidth='lg'
        sx={(theme) => ({
          marginTop: theme.spacings.lg,
          marginBottom: theme.spacings.xxl,
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: {
              xs: `repeat(2, 1fr)`,
              sm: `repeat(3, 1fr)`,
              md: `repeat(6, 1fr)`,
            },
            rowGap: theme.spacings.lg,
            columnGap: theme.spacings.md,
          })}
        >
          {category?.children?.map((item) => {
            if (!item?.url_path || !item.uid || !item.name) return null
            return (
              <Box
                key={item.uid}
                sx={(theme) => ({
                  display: 'grid',
                  gap: theme.spacings.xs,
                  alignContent: 'space-between',
                })}
              >
                <ButtonBase key={item.name} href={item.url_path}>
                  <Box
                    sx={(theme) => ({
                      width: '100%',
                      minWidth: '100%',
                      aspectRatio: '1',
                      background: theme.palette.action.disabledBackground,
                    })}
                  >
                    {item?.image && (
                      <Image
                        layout='fill'
                        src={item.image}
                        width={100}
                        height={100}
                        sizes='250px'
                        alt={item.name || ''}
                        sx={{ objectFit: 'cover', display: 'block' }}
                        quality={84}
                      />
                    )}
                  </Box>
                </ButtonBase>

                <Typography
                  variant='h6'
                  component='h2'
                  color='textPrimary'
                  sx={{ justifySelf: 'center', wordBreak: 'break-word', textAlign: 'center' }}
                >
                  {item.name}
                </Typography>

                <Button
                  key={item.url_path}
                  href={item.url_path}
                  variant='pill'
                  size='medium'
                  color='primary'
                  sx={(theme) => ({ justifySelf: 'center', paddingX: theme.spacings.md })}
                >
                  <Trans id='View' />
                </Button>
              </Box>
            )
          })}
        </Box>
      </Container>
    </>
  )
}
