import { FormAutoSubmit } from '@graphcommerce/ecommerce-ui'
import { useProductFiltersPro } from '@graphcommerce/magento-product'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Box, Theme, useMediaQuery } from '@mui/material'
import React from 'react'

export type ProductFiltersProLayoutSidebarProps = {
  clearAll?: React.ReactNode
  sidebarFilters?: React.ReactNode
} & Partial<OwnerProps>

type OwnerProps = {
  headerPosition: 'before'
}

const name = 'ProductFiltersProLayoutSidebar' as const
const parts = ['root', 'content'] as const
const { withState } = extendableComponent<OwnerProps, typeof name, typeof parts>(name, parts)

export function ProductFiltersProLayoutSidebar(props: ProductFiltersProLayoutSidebarProps) {
  const { clearAll, sidebarFilters, headerPosition = 'before' } = props

  const { form, submit } = useProductFiltersPro()
  const classes = withState({ headerPosition })

  // We only need to auto-submit when the layout is not sidebar and we're viewing on desktop
  const m = useMediaQuery<Theme>((t) => t.breakpoints.down('md'), { defaultMatches: false })
  const autoSubmitDisabled = m

  return (
    <Box
      className={classes.content}
      sx={(theme) => ({
        my: theme.spacings.xl,
        minWidth: '230px',
        maxWidth: '250px',
        display: { xs: 'none', md: 'block' },
        '& .MuiButtonBase-root.ActionCard-root.variantDefault.layoutList': {
          my: 0,
          py: 0,
        },
        '& .MuiButtonBase-root.ActionCard-root.variantDefault.layoutList:after': {
          display: 'none',
        },
        '& .ActionCardLayout-root.layoutList': {
          pt: 0,
          mb: 1,
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
          minHeight: 'unset',
          py: '5px',
          typography: theme.typography.subtitle1,
          fontWeight: '700',
          fontVariationSettings: "'wght' 550",
        },
        '& .MuiAccordionSummary-content .MuiButton-root': {
          py: 0,
        },
        '& .MuiPaper-root.Mui-expanded': {
          borderBottom: '1px solid #ededed',
        },
        '& .PriceSlider-container': {
          pt: theme.spacings.xs,
          pb: 1,
          pl: 0,
        },
        '& .MuiSlider-thumb.MuiSlider-thumbSizeLarge': {
          height: '22px',
          width: '22px',
        },
        '& .MuiAccordionDetails-root .MuiLoadingButton-root': {
          mt: '-10px',
          mb: '4px',
          px: '6px',
          '&:hover': { backgroundColor: 'unset' },
        },
        '& .ActionCardList-root .ActionCard-root': {
          px: '6px',
          '&.selected': {
            fontWeight: '700',
            fontVariationSettings: "'wght' 550",
            color: theme.palette.secondary.main,
            backgroundColor: 'unset',
          },
        },
      })}
    >
      <FormAutoSubmit control={form.control} disabled={autoSubmitDisabled} submit={submit} />
      {sidebarFilters && (
        <>
          {clearAll}
          {sidebarFilters}
        </>
      )}
    </Box>
  )
}
